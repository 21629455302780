import React from 'react';
import './summary.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

const isGood = (percentage, invert) => {
  if (invert) {
    return percentage > 0;
  }

  return percentage < 0;
};

const calculatePercentageChange = (value, original) => Math.round(((value - original) / original) * 100);

const getCarbonEquivalent = (monthlyCarbon) => {
  const comparisons = [
    [80, 'driving from London to Glascow' ],
  ];

  const remaining = [...comparisons];

  while(remaining.length) {
    const [comparison, text] = remaining.pop();
    if (monthlyCarbon > comparison) {
      return text;
    }
  }
};

const SummaryComparison = ({ percentage }) => {
  const icon = percentage === 0 ? '' : (percentage < 0 ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleUp} />);

  if (isNaN(percentage) || !isFinite(percentage)) {
    return <div className={"summary-item-compare"}></div>
  }
  return <div className={"summary-item-compare"}>{icon} {Math.abs(percentage)}%</div>;
};

const SummaryItem = ({ title, units, value, comparedTo, unitsPrefixed = false, neutral, invert}) => {
  const percentage = calculatePercentageChange(value, comparedTo);
  const className = !comparedTo || percentage === 0 || neutral ? '' : (isGood(percentage, invert) ? 'summary-item-good' : 'summary-item-bad');

  return <li className={className}>
    <div className="summary-item-title">{title}</div>
    <div className="summary-item-text">
      {unitsPrefixed ? <div className="summary-item-units">{units}</div> : <></>}
      <div className="summary-item-value">{isNaN(value) ? '-' : value.toFixed(2)}</div>
      <div className="summary-item-details">
        {typeof comparedTo !== 'undefined' ? <SummaryComparison percentage={percentage} /> : <></>}
        {!unitsPrefixed ? <div className="summary-item-units">{units}</div> : <></>}
      </div>
    </div>
    
  </li>;
};

const SummaryList = ({
  data: {
    heatGenerated,
    electricityUsed,
    estimatedCop,
    costOfElectricity,
    carbon,
  },
  comparison
}) => <ul className="summary-items">
  <SummaryItem value={heatGenerated} comparedTo={comparison?.heatGenerated} neutral={true} title="Heat Generated" units="KWh" />
  <SummaryItem value={electricityUsed} comparedTo={comparison?.electricityUsed} title="Energy Consumed" units="KWh" />
  <SummaryItem value={estimatedCop} comparedTo={comparison?.estimatedCop} title="Estimated CoP" invert={true} />
  <SummaryItem value={costOfElectricity} comparedTo={comparison?.costOfElectricity} title="Cost of electricity" units="£" unitsPrefixed={true} />
  <SummaryItem value={carbon} comparedTo={comparison?.carbon} title="Carbon emissions" units="kg" />
</ul>;

const homelyWon = (summary, homelySummary) => {
  const electricitySaved = homelySummary.costOfElectricity - summary.costOfElectricity;
  const electricityPercentage = calculatePercentageChange(homelySummary.costOfElectricity, summary.costOfElectricity);
  const carbonSavedDaily = homelySummary.carbon - summary.carbon;
  const carbonSavedMonth = carbonSavedDaily * 31;
  const carbonEquiv = getCarbonEquivalent(carbonSavedMonth);

  return [
    `Homely came out on top! With Homely controlling the heatpump you could have savings of up to £${Math.abs(electricitySaved).toFixed(2)} (${Math.abs(electricityPercentage)}%) a day`,
    ...(carbonEquiv ? [`You could also save ${Math.abs(carbonSavedDaily).toFixed(2)}kg of carbon emisions a day, that mounts up, over a month that could be the equivalent of ${carbonEquiv}`] : []),
  ];
};

const userWon = () => {
  return ["Wow, you beat Homely! Send a picture of your calculator to us!"];
};

const Outcome = ({ summary, homelySummary, data }) => {
  // const missedSetPoints = data.filter(({ set, indoor }) => typeof set !== 'undefined' && indoor < set);

  // if (missedSetPoints.length) {
  //   return <div className="summary-outcome">
  //     <p>Sorry! Some of your temperatures are below what the set temperatures, try adding more heating</p>
  //   </div>;
  // }

  const messages = (summary.estimatedCop > homelySummary.estimatedCop) ? userWon(summary, homelySummary) : homelyWon(summary, homelySummary); 

  return <div className="summary-outcome">
    <>{messages.map(message => <p>{message}</p>)}</>
  </div>;
};

const Summary = ({ summary, data }) => {
  const homelySummary = {
    heatGenerated: 6.29,
    electricityUsed: 2.17,
    estimatedCop: 2.95,
    costOfElectricity: 0.61,
    carbon: 0.51
  };

  return <div className="summary-container">
    <div className="summary">
      <div className="summary-rows">
        <div className="summary-row">
          <div className="summary-row-content">
            <div className="summary-row-title">You</div>
            <SummaryList data={summary} />
          </div>
        </div>
        <div className="summary-row">
          <div className="summary-row-content">
            <div className="summary-row-title">Homely</div>
            <SummaryList data={homelySummary} comparison={summary} />
          </div>
        </div>
      </div>
      <Outcome summary={summary} homelySummary={homelySummary} data={data} />
    </div>
  </div>;
};

export { Summary };
