const conditions = {
  'alwaysZero': {
    conditions: [
      { outdoor: 0 },
      { outdoor: 0 },
      { outdoor: 0 },
      { outdoor: 0 },
      { outdoor: 0 },
      { outdoor: 0 },
      { outdoor: 0 },
      { outdoor: 0 },
      { outdoor: 0 },
      { outdoor: 0 },
      { outdoor: 0 },
      { outdoor: 0 },
      { outdoor: 0 },
      { outdoor: 0 },
      { outdoor: 0 },
      { outdoor: 0 },
      { outdoor: 0 },
      { outdoor: 0 },
      { outdoor: 0 },
      { outdoor: 0 },
      { outdoor: 0 },
      { outdoor: 0 },
      { outdoor: 0 },
    ],
    profiles: {
      'weatherComp': [
        { flowTemp: 37.1 },
        { flowTemp: 37.1 },
        { flowTemp: 37.1 },
        { flowTemp: 37.1 },
        { flowTemp: 37.1 },
        { flowTemp: 37.1 },
        { flowTemp: 37.1 },
        { flowTemp: 37.1 },
        { flowTemp: 37.1, set: 20 },
        { flowTemp: 37.1, set: 20 },
        { flowTemp: 37.1 },
        { flowTemp: 37.1 },
        { flowTemp: 37.1 },
        { flowTemp: 37.1 },
        { flowTemp: 37.1 },
        { flowTemp: 37.1 },
        { flowTemp: 37.1 },
        { flowTemp: 37.1 },
        { flowTemp: 37.1, set: 20 },
        { flowTemp: 37.1, set: 20 },
        { flowTemp: 37.1, set: 20 },
        { flowTemp: 37.1, set: 20 },
        { flowTemp: 0, set: 20, locked: true },
      ],
      'thermostat': [
        { flowTemp: 0 },
        { flowTemp: 0 },
        { flowTemp: 0 },
        { flowTemp: 0 },
        { flowTemp: 0 },
        { flowTemp: 55 },
        { flowTemp: 55 },
        { flowTemp: 55 },
        { flowTemp: 0, set: 20 },
        { flowTemp: 0, set: 20 },
        { flowTemp: 0 },
        { flowTemp: 0 },
        { flowTemp: 0 },
        { flowTemp: 0 },
        { flowTemp: 0 },
        { flowTemp: 55 },
        { flowTemp: 55 },
        { flowTemp: 55 },
        { flowTemp: 0, set: 20 },
        { flowTemp: 55, set: 20 },
        { flowTemp: 0, set: 20 },
        { flowTemp: 0, set: 20 },
        { flowTemp: 0, set: 20, locked: true },
      ],
      'homely': [
        { flowTemp: 33.10 },
        { flowTemp: 33.50 },
        { flowTemp: 34.17 },
        { flowTemp: 36.48 },
        { flowTemp: 37.45 },
        { flowTemp: 38.35 },
        { flowTemp: 38.94 },
        { flowTemp: 41.30 },
        { flowTemp: 37.12, set: 20 },
        { flowTemp: 37.17, set: 20 },
        { flowTemp: 33.10 },
        { flowTemp: 33.50 },
        { flowTemp: 34.12 },
        { flowTemp: 36.44 },
        { flowTemp: 37.38 },
        { flowTemp: 38.22 },
        { flowTemp: 38.91 },
        { flowTemp: 41.20 },
        { flowTemp: 37.10, set: 20 },
        { flowTemp: 37.14, set: 20 },
        { flowTemp: 37.17, set: 20 },
        { flowTemp: 37.13, set: 20 },
        { flowTemp: 0, set: 20, locked: true },
      ],
    }
  },
};

export default conditions;