import React from 'react';
import './options.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, } from '@fortawesome/free-solid-svg-icons';

const Options = ({ options, conditions, dispatch }) => {
  const optionClick = (key, value) => {
    dispatch({ event: 'optionSet', key, value });
  };

  return <div className="options">
    <div className="options-controls">
      <button onClick={optionClick.bind(undefined, 'profile', 'thermostat')} className={(options.profile === 'thermostat' ? 'selected' : '')}>Thermostat</button>
      <button onClick={optionClick.bind(undefined, 'profile', 'weatherComp')} className={(options.profile === 'weatherComp' ? 'selected' : '')}>Weather Compensation</button>
      <button onClick={optionClick.bind(undefined, 'profile', 'homely')} className={(options.profile === 'homely' ? 'selected' : '')}>Homely</button>
    </div>
    <button className="reset-button" onClick={() => dispatch({ event: 'reset' })}><FontAwesomeIcon icon={faArrowsRotate} /> Reset Thermostat</button>
  </div>;
};

export { Options };
