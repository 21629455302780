import React, { useReducer } from 'react';
import './App.css';
import { Summary } from './components/summary';
import { Graph } from './components/graph';
import { Grid } from './components/grid';
import { Sliders } from './components/sliders';
import { Options } from './components/options';
import calculate from './actions/calculations';
import conditions from './conditions';

const constants = {
  initialConditions: 20,
  lossOfTempOverHour: 0.5,
  uValue: 0.18,
  area: 80,
};

const buildState = (
  constants,
  conditions,
  values,
  options,
  flowTemperatures,
) => {
  const mergedValues = (values ? values : conditions[options.conditions].profiles[options.profile]).map((current, i) => ({
    ...current,
    ...conditions[options.conditions].conditions[i],
  }));

  return {
    ...calculate(constants, mergedValues),
    options,
    flowTemperatures,
  }
};

function App() {
  const [{ data, options, summary }, dispatch] = useReducer((state, event) => {
    const { data, flowTemperatures = [], options } = state;

    let newValues = [...data];
    const newOptions = {...options};

    if (event.event === 'reset') {
      newValues = undefined;
    }

    if (event.event === 'flowTempUpdated') {
      if (data[event.index].flowTemp !== 0) {
        flowTemperatures[event.index] = parseFloat(event.value);
      }

      if (parseFloat(event.value) === 29.75) {
        newValues[event.index].flowTemp = 0;
      } else {
        newValues[event.index].flowTemp = parseFloat(event.value);
      }
    }

    if (event.event === 'flowTempToggled') {
      if (event.value) {
        newValues[event.index].flowTemp = flowTemperatures[event.index] || 37;
      } else {
        if (data[event.index].flowTemp !== 0) {
          flowTemperatures[event.index] = data[event.index].flowTemp;
        }
        newValues[event.index].flowTemp = 0;
      }
    }

    if (event.event === 'optionSet') {
      newOptions[event.key] = event.value;
      if(event.key === 'profile' || event.key === 'conditions') {
        newValues = undefined;
      }
    }

    return buildState(
      constants,
      conditions,
      newValues,
      newOptions,
      flowTemperatures,
    );
  }, buildState(
    constants,
    conditions,
    undefined,
    {
      style: 'sliders',
      conditions: 'alwaysZero',
      profile: 'thermostat',
    },
  ));

  return (
    <div className="App">
      <div className="header">
        <img src="homely-logo.svg" height="50" alt="Homely" />
        Think Heat Pump
      </div>
     <Summary summary={summary} data={data} />
     <Options options={options} conditions={conditions} dispatch={dispatch} />
     <Graph data={data} />
     {options.style === 'sliders' ? <Sliders data={data} dispatch={dispatch} /> : <Grid data={data} dispatch={dispatch} />}
    </div>
  );
}

export default App;
