import React from 'react';
import utils from '../../utils'; 
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarController,
  LineController,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  BarController,
  LineController,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);

const Graph = ({ data }) => {
  return <>
    <Chart type="bar" options={{
      scales: {
        yAxis: {
          label: 'fsd',
          max: 60,
        }
      }
    }} data={{
      datasets: [
        { type: 'bar', label: 'Flow temp (C)', data: data.map(({ flowTemp }) => flowTemp) },
        { type: 'line', label: 'CoP', data: data.map(({ cop }) => !cop ? undefined : cop), borderColor: '#F5C342' },
        { type: 'line', label: 'Internal (C)', data: data.map(({ indoor }) => indoor || 0), borderColor: '#ED7D31', },
        { type: 'line', label: 'Set Temperature (C)', data: data.map(({ set }) => set), borderColor: '#4472C4' },
      ],
      options: { spanGaps: false },
      labels: data.map((n, i) => utils.toHour(i))
    }} />
  </>;
};

export { Graph };
