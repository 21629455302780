import flowTempHeat from '../../flow-temp-heat.json';
import interpolatedCops from '../../interpolated-cops.json';

const calculateSummary = (data) => {
  const heatGenerated = data.reduce((total, { heatGenerated }) => total + (heatGenerated || 0), 0) / 1000;
  const electricityUsed = data.reduce((total, { electricityUsed }) => total + (electricityUsed || 0), 0) / 1000;
  const estimatedCop = data.reduce((total, { cop }) => total + (cop || 0), 0) / data.filter(({ cop }) => cop).length;

  return {
    heatGenerated,
    electricityUsed,
    estimatedCop,
    costOfElectricity: electricityUsed * 0.28,
    carbon: electricityUsed * 0.233,
  };
};

const getValueFromGrid = (grid, y, x) => {
  if (y < 0 || x < 0 || isNaN(y) || isNaN(x)) {
    return;
  }

  if (y > grid.length - 1 || x > grid[y].length - 1) {
    return;
  }

  return grid[y][x];
};

const calculateHeatGenerated = (indoor, flowTemp) => {
  if (flowTemp === 0) {
    return 0;
  }

  const minFlow = flowTempHeat[0][1];
  const minTemp = flowTempHeat[1][0];
  const diffFlow = (flowTempHeat[0][2] * 100 - flowTempHeat[0][1] * 100) / 100;
  const diffInterval = (flowTempHeat[2][0] - flowTempHeat[1][0]);

  const flowTempIndex = Math.round(((flowTemp - minFlow) / diffFlow) + 1);
  const tempIndex = Math.round(((indoor - minTemp) / diffInterval) + 1);


  return parseFloat(getValueFromGrid(flowTempHeat, tempIndex, flowTempIndex));
};

const calculateCop = (outdoor, flowTemp) => {
  if (flowTemp === 0) {
    return 0;
  }

  const outdoorStep = parseFloat((interpolatedCops[0][2] - interpolatedCops[0][1]).toFixed(1));
  const flowStep = parseFloat((interpolatedCops[2][0] - interpolatedCops[1][0]).toFixed(1));
  const flowTempIndex = Math.round(((flowTemp - interpolatedCops[1][0]) / flowStep) + 1);
  const outdoorTempIndex = Math.round(((outdoor - interpolatedCops[0][1]) / outdoorStep) + 1);

  return parseFloat(getValueFromGrid(interpolatedCops, flowTempIndex, outdoorTempIndex));
};

const calculate = (constants, values) => {
  const thermalInertia = constants.lossOfTempOverHour / (0.18 * 80 * (constants.initialConditions))

  const { data } = values.reduce(({ last, data }, currentRow) => {
    const { outdoor, flowTemp, ...rest } = currentRow;
    const indoor = last ? (last.indoor + ((last.heatGenerated - last.heatLoss) * thermalInertia)) : constants.initialConditions;

    const heatGenerated = calculateHeatGenerated(indoor, flowTemp);
    const cop = calculateCop(outdoor, flowTemp);
    const electricityUsed = heatGenerated / cop;

    const calculatedRow = {
      ...rest,
      outdoor,
      indoor,
      flowTemp,
      heatLoss: (indoor - outdoor) * (constants.area * constants.uValue),
      heatGenerated,
      cop,
      electricityUsed,
      cost: (electricityUsed * 0.2) / 1000,
    };

    data.push(calculatedRow);
    
    return { last: calculatedRow, data };
  }, { data: [] });

  return {
    data,
    values,
    summary: calculateSummary(data),
  };
};

export default calculate;
