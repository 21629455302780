import React from 'react';
import './grid.css';

const min = 35;
const max = 55;

const Grid = ({ data, dispatch }) => {
  const updateField = (index, e) => {
    dispatch({
      event: 'flowTempUpdated',
      index,
      value: e.target.value,
    });
  };
  
  const rows = data.map((row, i) => <tr key={`grid__row__${i}`}>
    <td>{`${i}`.padStart(2, '0')}:00</td>
    <td>{row.set}</td>
    <td>{row.outdoor.toFixed(2)}</td>
    <td>{row.indoor.toFixed(2)}</td>
    <td>{row.heatLoss.toFixed(2)}</td>
    <td><input type="number" value={row.flowTemp} onChange={updateField.bind(undefined, i)} min={min} max={max} className="grid-flowTemperature" /></td>
    <td>{row.heatGenerated.toFixed(2)}</td>
    <td>{row.cop.toFixed(2)}</td>
    <td>{row.electricityUsed.toFixed(2)}</td>
    <td>{row.cost.toFixed(2)}</td>
  </tr>);

  return <table>
    <thead>
      <tr>
        <td>Time</td>
        <td>Set temperature (C)</td>
        <td>Outside temperature</td>
        <td>Internal temp (C)</td>
        <td>Heat loss (W)</td>
        <td>Flow temperature</td>
        <td>Heat Generated (W)</td>
        <td>CoP</td>
        <td>Electricity consumed (Wh)</td>
        <td>Cost £</td>
      </tr>
    </thead>
    <tbody>
      {rows}
    </tbody>
  </table>;
};

export { Grid };
