import React from 'react';
import './sliders.css';

const Sliders = ({ data, dispatch }) => {
  const valueChanged = (index, e) => {
    dispatch({
      event: 'flowTempUpdated',
      index,
      value: e.target.value,
    });
  };

  return <div className="sliders">
    {data.map((item, i) =>  <div className="slider-item" key={`slider__${i}`}>
      <input type="range"
        className="slider-item-input"
        value={item.flowTemp}
        min="29.75"
        max="55"
        step="0.25"
        onChange={valueChanged.bind(undefined, i)}
        disabled={data.length - 1 === i}
      />

      <div className="slider-item-fields">
        {item.flowTemp.toFixed(2)}
      </div>
    </div>)}
  </div>
};

export { Sliders };
